<template>
  <div
    class="cart-line__product"
    :class="{ 'cart-line__product-freegift': isFreegift }"
  >
    <div class="cart-line__product-visual">
      <AppLink :to="item.product.product.url">
        <img
          :src="image"
          :alt="item.product.name"
        >
      </AppLink>
    </div>
    <div class="cart-line__product-content">
      <div>
        <div
          v-if="item.product?.name"
          class="cart-line__product-model"
        >
          <AppLink :to="item.product.product.url">
            <span>{{ item.product.name }}</span>
          </AppLink>
        </div>
        <span
          v-if="item.product?.product?.descriptiveName"
          class="cart-line__product-name"
          v-text="item.product.product.descriptiveName"
        />
        <CatalogDeliveryPromise
          v-if="!isFreegift"
          :item="item.deliveryPromise"
          class="cart-line__delivery"
        />
        <table
          v-if="item?.configurable_options"
          class="cart-line__product-data"
        >
          <tr
            v-for="(option, index) in item.configurable_options"
            :key="index"
          >
            <td>
              <span>
                {{ option?.value_label }}
              </span>
            </td>
          </tr>
          <tr v-if="!editable">
            <td>{{ $t('Quantity') }} {{ quantity }}</td>
          </tr>
        </table>
      </div>

      <div
        v-if="editable"
        class="cart-line__product-remove"
        @click.prevent="removeItem"
      >
        <IconClose class="icon icon--close" />
        <span class="visually-hidden">{{ $t('Remove') }}</span>
      </div>
    </div>
    <div
      v-if="editable"
      class="cart-line__product-update"
    >
      <div
        class="cart-line__product-action"
      >
        <div
          v-if="isFreegift"
          class="cart-line__product-quantity"
        >
          <div class="cart-line__quantity">
            {{ $t('Quantity') }}: {{ quantity }}
          </div>
          <!-- <span
            class="cart-line__product-price"
          >{{ $t('Free') }}</span> -->
        </div>
        <div
          v-else
          class="cart-line__product-quantity"
        >
          <select
            v-if="quantity <= threshold && quantity % qtyIncrements === 0"
            :value="quantity"
            :disabled="pending || !maxSaleQty"
            @change="updateItem(parseInt($event.target.value))"
          >
            <option
              v-for="q in quantityList"
              :key="q"
              :value="q"
              v-text="q"
            />
            <option
              v-if="threshold <= maxSaleQty"
              :value="threshold + qtyIncrements"
            >
              {{ $t('More than {number}', { number: threshold }) }}
            </option>
          </select>
          <input
            v-else
            type="number"
            class="cart-line__product-quantity"
            :step="qtyIncrements"
            :maxlength="maxSaleQty"
            :value="quantity"
            @change="updateItem(parseInt($event.target.value))"
          >
          <ProductPrice
            class="cart-line__product-price"
            :prices="unitPrice"
          />
        </div>
      </div>
      <div
        class="cart-line__product-totalprice"
      >
        <span
          v-if="isFreegift"
          class="label label--sale cart-line__product-totalprice"
        >{{ $t('Free') }}</span>
        <template v-else>
          <ProductPrice
            :hide-discount="true"
            :prices="totalPrice"
          />
          <template v-if="isPersonalized && extraCosts.finalPrice">
            <br>
            +
            <ProductPrice

              :hide-discount="true"
              :prices="extraCosts"
            />
          </template>
        </template>
      </div>
    </div>
    <div
      v-else
      class="cart-line__product-totalprice"
    >
      <span
        v-if="isFreegift"
        class="label label--sale"
      >{{ $t('Free') }}</span>
      <template v-else>
        <ProductPrice
          v-if="isPersonalized"
          :hide-discount="true"
          :prices="{
            regularPrice: totalPrice.regularPrice + (extraCosts.regularPrice || 0),
            finalPrice: totalPrice.finalPrice + extraCosts.finalPrice,
          }"
        />
        <ProductPrice
          v-else
          :hide-discount="true"
          :prices="totalPrice"
        />
      </template>
      <div class="cart-line__quantity">
        {{ $t('Quantity') }}: {{ quantity }}
      </div>
    </div>
    <div
      v-if="hasErrors"
      class="cart-line__product-error"
    >
      <p
        v-for="(error, index) in item.errors"
        :key="`error-${index}`"
        v-text="error.message"
      />
    </div>

    <div v-if="isPersonalized">
      <CartCustomizableOptions
        v-if="editable"
        class="cart-line__personalize"
        :item="item"
      >
        <template
          v-if="extraCosts.finalPrice / item.quantity"
          #price
        >
          <span class="cart-line__personalize-price">
            + <ProductPrice
              :hide-discount="true"
              :prices="{
                regularPrice: (extraCosts.regularPrice || 0) / item.quantity,
                finalPrice: extraCosts.finalPrice / item.quantity,
              }"
            />
          </span>
        </template>
      </CartCustomizableOptions>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { MagentoCartItem } from '~layers/app/types/magento'
import AppLink from '~layers/app/components/App/Link.vue'

export interface Props {
  item: MagentoCartItem
  editable?: boolean
}
const props = withDefaults(defineProps<Props>(), { editable: false })
const { item } = toRefs(props)
const { updateCartItem, removeCartItem, addedToCart, pending } = useCart()
const { public: config } = useRuntimeConfig()
const threshold = ref<number>(10)
const quantity = ref<number>(item.value?.quantity || 0)

const hasErrors = computed<boolean>(() =>
  !!item.value?.errors,
)

const simpleProduct = computed(() =>
  item.value.configured_variant || item.value.product,
)

const isPersonalized = computed<boolean>(() => !!item.value?.customizable_options?.length)

const extraCosts = computed(() => {
  return {
    regularPrice: null,
    finalPrice: item.value.prices.row_total_including_tax.value - (simpleProduct.value?.price_range?.minimum_price?.final_price?.value * item.value.quantity),
  }
})

const unitPrice = computed(() => {
  return {
    regularPrice: simpleProduct.value?.price_range?.minimum_price?.regular_price?.value,
    finalPrice: simpleProduct.value?.price_range?.minimum_price?.final_price?.value,
  }
})

const totalPrice = computed(() => {
  return {
    finalPrice: simpleProduct.value?.price_range?.minimum_price?.final_price?.value * item.value.quantity,
    regularPrice: simpleProduct.value?.price_range?.minimum_price?.regular_price?.value * item.value.quantity,
  }
})

const isFreegift = computed(() =>
  item.value.prices.price_including_tax.value < 0.1,
)

const image = computed<string>(() => {
  if (simpleProduct.value?.image?.url && !simpleProduct.value?.image?.url.includes('placeholder')) {
    return getProductImage(simpleProduct.value.image.url, 'f=rs:fit:400:400')
  }
  if (item.value.product.image?.url && !item.value.product.image?.url.includes('placeholder')) {
    return getProductImage(item.value.product.image.url, 'f=rs:fit:400:400')
  }
  return `${config.xcdnUrl}/media/catalog/product/placeholder/default/Product.FallBack.jpg`
})

const configuredMaxSaleQty = computed<number>(() =>
  simpleProduct.value?.stockItem?.max_sale_qty || 1000,
)

const maxSaleQty = computed<number>(() => {
  if (simpleProduct.value?.stockItem?.backorders && simpleProduct.value?.stockItem?.max_sale_qty === 0) {
    return 1000
  }
  let _maxSaleQty = configuredMaxSaleQty.value
  if (simpleProduct.value?.stockItem?.use_config_max_sale_qty === 1 && configuredMaxSaleQty.value === 0) {
    _maxSaleQty = 10000
  }
  return Math.min(Math.min(_maxSaleQty, 10000), simpleProduct.value?.stock_left || 10000)
})

const qtyIncrements = computed<number>(() =>
  simpleProduct.value?.stockItem?.qty_increments || 1,
)

const quantityList = computed<number[]>(() => {
  const maxAmount = Math.min(threshold.value, maxSaleQty.value)
  const max = Math.floor(maxAmount / qtyIncrements.value)
  return [...Array(max).keys()].map(i => (i + 1) * qtyIncrements.value)
})

const { removeFromCart } = useDataLayer()
const updateItem = async (inputValue?: number) => {
  if (!isNaN(inputValue)) {
    quantity.value = parseInt(inputValue)
  }
  if (quantity.value > maxSaleQty.value) {
    quantity.value = maxSaleQty.value
  }
  const oldQty = item.value.quantity
  await updateCartItem({
    cart_item_id: +item.value.id,
    quantity: quantity.value,
  }, true)
  if (addedToCart.value?.length && addedToCart.value.length > 0) {
    quantity.value = +item.value.quantity
  }
  const newQty = item.value.quantity
  if (oldQty > newQty) {
    removeFromCart({ ...item.value, quantity: oldQty - newQty })
  }
}

const removeItem = async () => {
  await removeCartItem(+item.value.id)
  removeFromCart(item.value)
}
</script>

<style src="~layers/app/components/Cart/Line.css" />
